import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Switch, Route, useLocation } from "react-router-dom";
import "./styles.scss";

import {
  PATH_PRICE_PREDICTION_PRICE_WATCH,
  PATH_PRICE_DROP_PROTECTION,
  PATH_CANCEL_FOR_ANY_REASON,
  PATH_HOTEL_CANCEL_FOR_ANY_REASON,
  PATH_DISRUPTION_PROTECTION,
  PATH_TRAVEL_OFFERS,
  PATH_TRAVEL_CREDITS,
  PATH_FROZEN_PRICES,
  PATH_BEST_PRICE_GUARANTEE,
  PATH_SITE_TERMS_OF_USE,
  PATH_TERMS_OF_SERVICE,
  PATH_HOME,
  PATH_PREMIER_COLLECTION,
  PATH_HOTEL_PRICE_DROP,
  PATH_MISSED_CONNECTION_GUARANTEE,
} from "../../../utils/paths";

import { ContentBlock } from "halifax";

import {
  BEST_PRICE_GUARANTEE_TITLE,
  BEST_PRICE_GUARANTEE_SUBTITLE,
} from "./TextConstants/bestPriceGuaranteeTextConstant";

import {
  CANCEL_FOR_ANY_REASON_TITLE,
  CANCEL_FOR_ANY_REASON_SUBTITLE,
  HOTEL_CANCEL_FOR_ANY_REASON_TITLE,
  HOTEL_CANCEL_FOR_ANY_REASON_SUBTITLE,
} from "./TextConstants/cancelForAnyReasonTextConstant";

import {
  FLIGHT_FROZEN_PRICES_TITLE,
  FLIGHT_FROZEN_PRICES_SUBTITLE,
} from "./TextConstants/frozenFlightPricesTextConstant";

import {
  PRICE_DROP_PROTECTION_TITLE,
  PRICE_DROP_PROTECTION_SUBTITLE,
  HOTEL_PRICE_DROP_PROTECTION_TITLE,
} from "./TextConstants/priceDropProtectionTextConstant";

import {
  PRICE_DROP_PROTECTION_BODY,
  PRICE_PREDICTION_PRICE_WATCH_BODY,
  BEST_PRICE_GUARANTEE_BODY,
  FROZEN_PRICES_BODY,
  FLIGHT_CFAR_TERMS_BODY,
  HOTEL_CFAR_TERMS_BODY,
  HOTEL_CFAR_TERMS_TABLE,
  DISRUPTION_PROTECTION_BODY,
  TRAVEL_CREDITS_BODY,
  HOTEL_PRICE_DROP_PROTECTION_BODY,
  MISSED_CONNECTION_GUARANTEE_BODY,
  MISSED_CONNECTION_GUARANTEE_TITLE,
  MISSED_CONNECTION_GUARANTEE_SUBTITLE,
} from "redmond";

import {
  TERMS_OF_SERVICE_TITLE,
  TERMS_OF_SERVICE_SUBTITLE,
  TERMS_OF_SERVICE_FROZEN_PRICES_FLIGHT_SUBTITLE,
  TERMS_OF_SERVICE_CANCEL_FOR_ANY_REASON_SUBTITLE,
  TERMS_OF_SERVICE_HOTEL_CANCEL_FOR_ANY_REASON_SUBTITLE,
  TERMS_OF_SERVICE_BODY,
  TERMS_OF_SERVICE_DISRUPTION_PROTECTION_SUBTITLE,
  TERMS_OF_SERVICE_TRAVEL_OFFERS_SUBTITLE,
  TERMS_OF_SERVICE_TRAVEL_CREDITS_SUBTITLE,
  TERMS_OF_SERVICE_PREMIER_COLLECTION_SUBTITLE,
  TERMS_OF_SERVICE_LIFESTYLE_COLLECTION_SUBTITLE,
  TERMS_OF_SERVICE_TREES_SUBTITLE,
  TREES_BODY,
  TERMS_OF_SERVICE_HOTEL_PRICE_DROP_PROTECTION_SUBTITLE,
  TERMS_OF_SERVICE_PRICE_MATCH_GUARANTEE,
  TERMS_OF_SERVICE_SELLER_OF_TRAVEL_DISCLOSURE,
  TERMS_OF_SERVICE_MISSED_CONNECTION_GUARANTEE_TITLE,
} from "./TextConstants/termsOfServiceTextConstant";
import {
  SITE_TERMS_OF_USE_TITLE,
  SITE_TERMS_OF_USE_SUBTITLE,
  SITE_TERMS_OF_USE_BODY,
} from "./TextConstants/siteTermOfUseTextConstant";

import {
  PRICE_PREDICTION_PRICE_WATCH_TITLE,
  PRICE_PREDICTION_PRICE_WATCH_SUBTITLE,
} from "./TextConstants/pricePredictionPriceWatchTextConstants";
import {
  DISRUPTION_PROTECTION_SUBTITLE,
  DISRUPTION_PROTECTION_TITLE,
} from "./TextConstants/disruptionProtectionTextConstant";
import {
  TRAVEL_OFFERS_TITLE,
  TRAVEL_OFFERS_SUBTITLE,
  TRAVEL_OFFERS_BODY,
  TRAVEL_OFFERS_FOOTER,
} from "./TextConstants/travelOffersTextContant";
import {
  TRAVEL_CREDITS_TITLE,
  TRAVEL_CREDITS_SUBTITLE,
} from "./TextConstants/travelCreditsTextContant";

import {
  PREMIER_COLLECTION_BODY,
  PREMIER_COLLECTION_SUBTITLE,
  PREMIER_COLLECTION_TITLE,
} from "./TextConstants/premierCollectionTextConstants";

import {
  LIFESTYLE_COLLECTION_BODY,
  LIFESTYLE_COLLECTION_FOOTER,
} from "./TextConstants/lifestyleCollectionTextConstants";

import clsx from "clsx";
import {
  AVAILABLE,
  getExperimentVariant,
  PREMIER_COLLECTION_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  HOTEL_PRICE_DROP_SMOKE_TEST,
  useExperiments,
  HOTEL_PRICE_DROP_SMOKE_TEST_25_DOLLAR,
  HOTEL_PRICE_DROP_SMOKE_TEST_FORCE_25_DOLLAR,
  getExperimentVariantCustomVariants,
  HOTEL_PRICE_DROP_SMOKE_TEST_VARIANTS,
  HOTEL_PRICE_DROP_SMOKE_TEST_10_DOLLAR,
  HOTEL_PRICE_DROP_SMOKE_TEST_50_DOLLAR,
} from "../../../context/experiments";

import dayjs from "dayjs";
import { getConfigTenant, getPortalName } from "@capone/common";

interface IBodyProps {
  language: string;
}

const Body = (_props: IBodyProps) => {
  const { hash } = useLocation();
  const expState = useExperiments();

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const premierCollection = getExperimentVariant(
    expState.experiments,
    PREMIER_COLLECTION_EXPERIMENT
  );
  const isPremierCollectionExperiment = React.useMemo(
    () => premierCollection === AVAILABLE,
    [premierCollection]
  );

  const hotelPriceDropSmokeTestExperiment = getExperimentVariantCustomVariants(
    expState.experiments,
    HOTEL_PRICE_DROP_SMOKE_TEST,
    HOTEL_PRICE_DROP_SMOKE_TEST_VARIANTS
  );
  const isHotelPriceDropAvailable =
    hotelPriceDropSmokeTestExperiment ==
      HOTEL_PRICE_DROP_SMOKE_TEST_25_DOLLAR ||
    hotelPriceDropSmokeTestExperiment ==
      HOTEL_PRICE_DROP_SMOKE_TEST_FORCE_25_DOLLAR ||
    hotelPriceDropSmokeTestExperiment ==
      HOTEL_PRICE_DROP_SMOKE_TEST_10_DOLLAR ||
    hotelPriceDropSmokeTestExperiment == HOTEL_PRICE_DROP_SMOKE_TEST_50_DOLLAR;

  const getBodyContent = (path: string) => {
    switch (path) {
      case PATH_PRICE_PREDICTION_PRICE_WATCH:
        return [
          PRICE_PREDICTION_PRICE_WATCH_TITLE,
          PRICE_PREDICTION_PRICE_WATCH_SUBTITLE,
          PRICE_PREDICTION_PRICE_WATCH_BODY(getConfigTenant()),
        ];
      case PATH_PRICE_DROP_PROTECTION:
        return [
          PRICE_DROP_PROTECTION_TITLE,
          PRICE_DROP_PROTECTION_SUBTITLE,
          PRICE_DROP_PROTECTION_BODY,
        ];
      case PATH_MISSED_CONNECTION_GUARANTEE:
        return [
          MISSED_CONNECTION_GUARANTEE_TITLE,
          MISSED_CONNECTION_GUARANTEE_SUBTITLE,
          MISSED_CONNECTION_GUARANTEE_BODY,
        ];
      case PATH_HOTEL_PRICE_DROP:
        if (!isHotelPriceDropAvailable) {
          return [];
        }
        return [
          HOTEL_PRICE_DROP_PROTECTION_TITLE,
          PRICE_DROP_PROTECTION_SUBTITLE,
          HOTEL_PRICE_DROP_PROTECTION_BODY,
        ];
      case PATH_FROZEN_PRICES:
        return [
          FLIGHT_FROZEN_PRICES_TITLE,
          FLIGHT_FROZEN_PRICES_SUBTITLE,
          FROZEN_PRICES_BODY,
        ];
      case PATH_BEST_PRICE_GUARANTEE:
        return [
          BEST_PRICE_GUARANTEE_TITLE,
          BEST_PRICE_GUARANTEE_SUBTITLE,
          BEST_PRICE_GUARANTEE_BODY,
        ];
      case PATH_CANCEL_FOR_ANY_REASON:
        return [
          CANCEL_FOR_ANY_REASON_TITLE,
          CANCEL_FOR_ANY_REASON_SUBTITLE,
          FLIGHT_CFAR_TERMS_BODY(getPortalName()),
        ];
      case PATH_HOTEL_CANCEL_FOR_ANY_REASON:
        return [
          HOTEL_CANCEL_FOR_ANY_REASON_TITLE,
          HOTEL_CANCEL_FOR_ANY_REASON_SUBTITLE,
          HOTEL_CFAR_TERMS_BODY(getPortalName()) + HOTEL_CFAR_TERMS_TABLE,
        ];
      case PATH_DISRUPTION_PROTECTION:
        return [
          DISRUPTION_PROTECTION_TITLE,
          DISRUPTION_PROTECTION_SUBTITLE,
          DISRUPTION_PROTECTION_BODY,
        ];
      case PATH_TRAVEL_OFFERS:
        return [
          TRAVEL_OFFERS_TITLE,
          TRAVEL_OFFERS_SUBTITLE,
          TRAVEL_OFFERS_BODY + TRAVEL_OFFERS_FOOTER,
        ];
      case PATH_PREMIER_COLLECTION:
        // Combine Premier and Lifestyle Collection text
        let premierBody =
          TERMS_OF_SERVICE_PREMIER_COLLECTION_SUBTITLE +
          PREMIER_COLLECTION_BODY +
          TERMS_OF_SERVICE_LIFESTYLE_COLLECTION_SUBTITLE +
          LIFESTYLE_COLLECTION_BODY +
          LIFESTYLE_COLLECTION_FOOTER;
        return [
          PREMIER_COLLECTION_TITLE,
          PREMIER_COLLECTION_SUBTITLE,
          premierBody,
        ];
      case PATH_TRAVEL_CREDITS:
        return [
          TRAVEL_CREDITS_TITLE,
          TRAVEL_CREDITS_SUBTITLE,
          TRAVEL_CREDITS_BODY,
        ];
      case PATH_SITE_TERMS_OF_USE:
        return [
          SITE_TERMS_OF_USE_TITLE,
          SITE_TERMS_OF_USE_SUBTITLE,
          SITE_TERMS_OF_USE_BODY(dayjs().year()),
        ];
      case PATH_TERMS_OF_SERVICE:
      default:
        let body = TERMS_OF_SERVICE_BODY;
        if (isHotelPriceDropAvailable) {
          body +=
            TERMS_OF_SERVICE_HOTEL_PRICE_DROP_PROTECTION_SUBTITLE +
            HOTEL_PRICE_DROP_PROTECTION_BODY;
        }
        body +=
          TERMS_OF_SERVICE_PRICE_MATCH_GUARANTEE + BEST_PRICE_GUARANTEE_BODY;
        body +=
          TERMS_OF_SERVICE_FROZEN_PRICES_FLIGHT_SUBTITLE + FROZEN_PRICES_BODY;
        body +=
          TERMS_OF_SERVICE_CANCEL_FOR_ANY_REASON_SUBTITLE +
          FLIGHT_CFAR_TERMS_BODY(getPortalName());
        body +=
          TERMS_OF_SERVICE_HOTEL_CANCEL_FOR_ANY_REASON_SUBTITLE +
          HOTEL_CFAR_TERMS_BODY(getPortalName()) +
          HOTEL_CFAR_TERMS_TABLE;
        body += TERMS_OF_SERVICE_DISRUPTION_PROTECTION_SUBTITLE;
        body += DISRUPTION_PROTECTION_BODY;
        if (isTravelWalletOfferExperiment) {
          body += TERMS_OF_SERVICE_TRAVEL_OFFERS_SUBTITLE + TRAVEL_OFFERS_BODY;
        }
        body +=
          TERMS_OF_SERVICE_TRAVEL_CREDITS_SUBTITLE +
          TRAVEL_CREDITS_BODY +
          TERMS_OF_SERVICE_TREES_SUBTITLE +
          TREES_BODY;
        body +=
          TERMS_OF_SERVICE_PREMIER_COLLECTION_SUBTITLE +
          PREMIER_COLLECTION_BODY +
          TERMS_OF_SERVICE_LIFESTYLE_COLLECTION_SUBTITLE +
          LIFESTYLE_COLLECTION_BODY;
        body +=
          TERMS_OF_SERVICE_MISSED_CONNECTION_GUARANTEE_TITLE +
          MISSED_CONNECTION_GUARANTEE_BODY;
        body += TERMS_OF_SERVICE_SELLER_OF_TRAVEL_DISCLOSURE;
        return [TERMS_OF_SERVICE_TITLE, TERMS_OF_SERVICE_SUBTITLE, body];
    }
  };

  // Usually you can scroll an id into view by using it's id as the URL hash.
  // But since terms-module and content loads after initial page load, this will scroll
  // elements with the matching hash and exist into view.
  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) element.scrollIntoView();
    }
  }, [hash]);

  const renderTerms = (path: string) => {
    const [title, subtitle, body] = getBodyContent(path);
    return <ContentBlock {...{ title, subtitle, body }} />;
  };

  return (
    <Box
      className={clsx("terms-body-container", {
        section:
          window.location.pathname !== PATH_TERMS_OF_SERVICE &&
          window.location.pathname !== PATH_SITE_TERMS_OF_USE,
      })}
    >
      <Switch>
        <Route
          path={[
            PATH_PRICE_PREDICTION_PRICE_WATCH,
            PATH_PRICE_DROP_PROTECTION,
            PATH_MISSED_CONNECTION_GUARANTEE,
            PATH_HOTEL_PRICE_DROP,
            PATH_CANCEL_FOR_ANY_REASON,
            PATH_HOTEL_CANCEL_FOR_ANY_REASON,
            PATH_DISRUPTION_PROTECTION,
            PATH_FROZEN_PRICES,
            PATH_BEST_PRICE_GUARANTEE,
            PATH_SITE_TERMS_OF_USE,
            PATH_TERMS_OF_SERVICE,
            PATH_TRAVEL_CREDITS,
            PATH_HOME,
            ...(isTravelWalletOfferExperiment ? [PATH_TRAVEL_OFFERS] : []),
            ...(isPremierCollectionExperiment ? [PATH_PREMIER_COLLECTION] : []),
          ]}
          render={(props) => renderTerms(props.location.pathname)}
          exact
        />
      </Switch>
    </Box>
  );
};

export default Body;
